<template>
  	<TableLayout service="client" single-route-name="clients.edit" :filters="filters" :column_filters="Object.keys(column_filters)" :actions="actions" :enabled_actions="enabled_actions">
		<template #toolbar-left>
			<h1 style="margin: auto">{{$tc('enduser',2)}}</h1>
		</template>
		<template #toolbar-right="{open}">
			<Button :label="$t('new')" icon="pi pi-plus" class="p-button-success p-mr-2" @click="open(false)" v-if="$store.permissions.add()"/>
		</template>
		
		<template #default="{open}">
			<Column field="name" sortable>
        <template #header="{data}">
          {{$t('nom')}}
        </template>
        <template #body="{data}">
					<router-link :to="{name: 'clients.edit', params: {id: data._id}}"
					             v-slot="{ href, route, navigate, isActive, isExactActive }"
					             custom>
						<Button @click="navigate" :label="data.name" class="p-button-text"/>
					</router-link>
				</template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" class="p-column-filter" @keydown.enter="filterCallback()"/>
        </template>
			</Column>
			<Column field="telephone">
        <template #header="{data}">
          {{$t('telephone')}}
        </template>
        <template #filter="{filterModel,filterCallback}" v-if="filterModel">
          <InputText type="text" v-model="filterModel.value" class="p-column-filter" @keydown.enter="filterCallback()"/>
        </template>
      </Column>
			<Column field="address_line_1">
        <template #header="{data}">
          {{$t('adresse')}}
        </template>
				<template #body="{data}">
					{{ data.billing_address_1 }}
					<br v-if="data.billing_address_2">
					{{ data.billing_address_2 }}
          <br v-if="data.billing_address_3">
          {{ data.billing_address_3 }}
          <br v-if="data.billing_address_4">
          {{ data.billing_address_4 }}
					<br>
					{{ data.billing_city }}, {{ mappedProvinces[data.billing_province] }}
					<br v-if="data.billing_postal_code">
					{{ data.billing_postal_code }}
				</template>
			</Column>
		</template>


	
	</TableLayout>


</template>

<script>
import TableLayout from "../Layouts/TableLayout";
import FormRadium from "@/components/FormPrime/FormRadium";
import {computed, ref, inject} from "vue";
import FormInput from "@/components/FormPrime/FormInput";
import {FilterMatchMode,FilterOperator} from 'primevue/api';

import {provinces} from "@/helpers/provinces";
import {useStore} from "@/store";
import {onBeforeRouteLeave, useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import {_GET} from "../../helpers/get";
const ObjectID = require("bson-objectid");



export default {
	name: 'Clients',
	components: {
		FormInput,
		FormRadium,
    TableLayout,
		Column: require('primevue/column').default,
		Button: require('primevue/button').default,
		InputNumber: require('primevue/inputnumber').default,
		RadioButton: require('primevue/radiobutton').default,
		InputText: require('primevue/inputtext').default,
		SplitButton: require('primevue/splitbutton').default,
		Dropdown: require('primevue/dropdown').default,
		InputMask: require('primevue/inputmask').default,
	},
  data(){
    return {
      column_filters: {
        name: 'string',
        telephone: 'string'
      }
    };
  },
	setup(props, {emit}) {
    const store = useStore();
    const router = useRouter();
    _GET.setProvider(store.radium);
    const i18n = useI18n();
    const own_filters = store.permissions.filters();

		const statusOptions = ref([
			{
				name: i18n.t('actif'),
				code: 'Active',
				css: 'badge badge-success'
			},
			{
				name: i18n.t('inactif'),
				code: 'Inactive',
        css: 'badge badge-danger'
			}
		]);
		const radium = useStore().radium;
    const enabled_actions = [];

    const actions = [];
    actions.push({
      label: i18n.t('submission'),
      icon: "pi pi-file",
      command(data) {
        router.push({path:'/submissions/edit/client/'+data._id});
      }
    });

    let filters = {
      'name': {value: null, matchMode: FilterMatchMode.CONTAINS},
      'telephone': {value: null, matchMode: FilterMatchMode.CONTAINS},
    };
    filters = {...filters,...own_filters};

		return {
      filters,
      store,
			actions,
      enabled_actions,
			tabs: {
				info: "Infos",
				notable: true,
				timeData: true,
			},
			statusOptions,
			statusMap: computed(() => {
				return statusOptions.value.reduce((a, v) => {
					a[v.code] = {name: v.name, css: v.css};
					return a
				}, {});
			}),
			mappedProvinces: computed(() => {
				return provinces.reduce((a, v) => {
					a[v.value] = v.label;
					return a;
				}, {});
			}),
		}
	},
  methods:{
  }
}
</script>